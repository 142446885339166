<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialogProvider" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="myform" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="body.name"
                      label="Nombre*"
                      :rules="onlyRequired"
                    ></v-text-field>
                    <span caption class="red--text">{{ nameError }}</span>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Número de Teléfono"
                      v-model="body.phone_number"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="body.address"
                      label="Dirección*"
                      :rules="onlyRequired"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="body.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" v-if="!hidden">
                    <v-text-field
                      label="NIT*"
                      v-model="body.nit"
                      :rules="nitRule"
                    ></v-text-field>
                    <span caption class="red--text">{{ nitError }}</span>
                  </v-col>
                  <v-col cols="12" md="4" v-if="!hidden">
                    <v-checkbox
                      :label="`Pequeño Contribuyente`"
                      v-model="body.disable_iva"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      :label="`Contravales`"
                      v-model="body.available_voucher"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" v-if="!hidden">
                    <v-checkbox
                      :label="`Crédito Disponible`"
                      v-model="body.credit_available"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    v-if="body.credit_available && !hidden"
                  >
                    <v-text-field
                      label="Días  de Crédito"
                      :rules="textRules"
                      v-model="body.credit_days"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cerrar </v-btn>
            <v-btn
              :disabled="!valid"
              v-if="options == 1"
              color="blue darken-1"
              text
              @click="createProvider()"
            >
              Guardar
            </v-btn>
            <v-btn
              v-else
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="updateProvider"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    dialogProvider: { type: Boolean, required: true },
    providerId: { type: Number, required: false },
    options: { type: Number, required: true },
    title: { type: String },
    hidden: { type: Boolean, required: false },
  },
  data: () => {
    return {
      valid: false,
      body: {
        name: "",
        address: "",
        nit: "",
        phone_number: "",
        email: "",
        credit_days: 0,
        available_voucher: false,
        credit_available: false,
        disable_iva: false,
        is_active: true
      },
      //rules
      emailRules: [rules.email],
      textRules: [rules.minLength(10)],
      onlyRequired: [rules.required],
      nitRule: [rules.required, rules.nit],
      valueRules: [rules.required, rules.minValue(0.1)],
      // error message
      nitError: undefined,
      nameError: undefined,
    };
  },
  methods: {
    getDataProvider() {
      requests.get("api/catalogs/providers/" + this.providerId).then((data) => {
        this.body = data.data;
      });
    },
    updateProvider() {
      if (this.$refs.myform.validate()) {
        requests
          .patch("api/catalogs/providers/" + this.providerId + "/", this.body)
          .then((response) => {
            if (response.status == 200) {
              this.$emit("refresh");
              this.value = "";
              this.$emit("refresh");
              this.close();
            } else {
              if ("nit" in response.data) {
                this.nitError = response.data.nit;
              } else {
                this.nitError = undefined;
              }
              if ("name" in response.data) {
                this.nameError = response.data.name[0];
              } else {
                this.nameError = undefined;
              }
            }
          });
      }
    },
    createProvider() {
      if (this.$refs.myform.validate()) {
        requests.post("api/catalogs/providers/", this.body).then((res) => {
          if (res.status == 201) {
            this.$toasted.global.info({ message: "Registro Creado" });
            this.close();
            this.$emit("refresh");
          } else {
            if ("nit" in res.data) {
              this.nitError = res.data.nit;
            } else {
              this.nitError = undefined;
            }
            if ("name" in res.data) {
              this.nameError = res.data.name[0];
            } else {
              this.nameError = undefined;
            }
          }
        });
      }
    },
    close() {
      this.$refs.myform.reset();
      this.$emit("close");
    },
  },
  mounted() {
    if (this.options == 2) {
      this.getDataProvider();
    }
  },
};
</script>